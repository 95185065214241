html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: Inter, serif;
    font-weight: 400;
    background-repeat: no-repeat;
    background-size: contain;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

svg {
    pointer-events: none;
}

button {
    all: unset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
    font: normal normal 18px / normal Urbane Rounded, serif;
    -webkit-text-fill-color: #434E54;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;

}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e2e5;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #e7eaee;
    border-radius: 10px;
}

.text_dots {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
