.container {
    border: none;
    display: inline-block;
    font: inherit;
    outline: none;
    position: relative;
    width: 100%;

}

.placeholder {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label {
    margin-bottom: 8px;
}

.placeholder {
    padding: 15px 21px;
    border-radius: 20px;
    background-color: #E7F6FE;
    cursor: pointer;
    user-select: none;
}

.block {
    position: absolute;
    top: 100%;
    overflow: hidden;
    color: #919395;
    background: #F7F7F7;
    border-radius: 8px;
    width: 100%;
    order: 1;
    padding: 12px;
    z-index: 8;
    user-select: none;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.option {
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    max-width: 100%;
    transition: background-color 180ms ease;
}

.text, .option {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.option:hover {
    background-color: rgba(255,255,255,.6);
}

.selected {
    background-color: white;
}



