.container {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.block {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: scroll;
}

.footer_container {
    display: flex;
    justify-content: space-between;
    background-color: #000;
    padding: 10px 40px;
    align-items: center;
}

.footer_btn_container {
    display: flex;
    gap: 0 16px;
}

.footer_text {
    color: white;
    font-size: 16px;
}
