:root {
    --gray: #EDEDED;
    --red: rgba(173, 43, 43, 1);
    --primary-text-color: #434E54;
    --secondary-text-color: #919395;

    --primary-button-color: rgba(159, 143, 91, 1);
    --secondary-button-color:white;
    --dark-blue:rgba(41, 56, 68, 1)
}
