.container {
    flex: 1;
    position: relative;
}

.scroll {
    overflow: auto;
    /*position: absolute;*/
    /*left: 0;*/
    /*top: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
}

.container thead tr {
    position: sticky;
    top: 0;
    z-index: 10;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    padding: 8px 15px;
    white-space: nowrap !important;
    font-weight: 300;
    font-size: 18px;
}

.scroll::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 5px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
    width: 5px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    width: 5px;
}
