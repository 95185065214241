.container {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    color: #94a3b8;
    font-size: 14px;
    gap: 0 32px;
}
.block {
    background-color: #EAECF0;
    padding: 2px;
    border-radius: 20px;
    width: 38px;
    display: flex;
    cursor: pointer;
}
.block > div {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: white;
    transition: all 180ms ease;
}
.block_switched {
    transition: all 180ms ease;
    background-color: var(--blue);
}
