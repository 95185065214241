.transparent_container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9998;
}

.container {
    user-select: none;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    z-index: 9999;
    cursor: pointer;
}

.label_container {
    display: flex;
    align-items: center;
    grid-gap: 0 10px;
}
.label {
    margin: 0 10px;
}

.block_container {
    position: absolute;
    right: 0;
    border-radius: 4px;
    transition: all 350ms linear;
    box-sizing: border-box;
    margin-top: 3px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    overflow: hidden;
    backdrop-filter: blur(25px);
}

.block {
    overflow-y: overlay;
    max-width: 250px;
    min-width: auto;
    max-height: 0;
    width: 100%;
    height: 100%;
    transition: all 1s ease;
    padding: 0;
}

.block > div > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.block::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.block_container_show {
    display: block;
    border: 1px solid #cbd5e1;
}

.block_show {
    max-height: 0;
    animation: show 350ms forwards 1 ease;
}

.block_hidden {
    animation: hidden 350ms forwards 1 ease;
}

@keyframes show {
    0% {
        max-height: 0px;
        padding: 0 6px;
    }

    100% {
        max-height: 300px;
        padding: 6px;
    }
}

@keyframes hidden {
    0% {
        max-height: 300px;
        padding: 6px;
    }

    100% {
        max-height: 0;
        padding: 0 6px;
    }
}

