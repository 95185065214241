.container {
    display: inline-flex;
    background-color: transparent;
    position: relative;
    user-select: none;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
}

.btn {
    padding: 12px 20px;
    font-size: 20px;
    background-color: #edeff0;
    color: #646464 !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    flex: 1;
    border: var(--border);
    white-space: nowrap;
}

.btn:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.btn_selected {
    color: var(--blue) !important;
}

.btn_selected > span {
    z-index: 2 !important;
    position: relative;
    white-space: nowrap;
}
.animation_block {
    background-color: white;
    position: absolute;
    left: 0;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1 !important;
}
.animation {
    transition: 160ms ease;
    transition-property: width, transform;
}
.divider {
    width: 2px;
    height: auto;
    background-color: rgb(233, 233, 233);
    margin: 6px 0;
}
