.l-radio {
    padding: 6px;
    display: inline-flex;
    cursor: pointer;
    transition: all 0.2s ease;
    margin: 8px 0;
    -webkit-tap-highlight-color: transparent;
    font-size: 12px;
    font-weight: 500;
    align-items: center;
    font-family: Roboto-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #646464;
}

.l-radio>input {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: none;
    border: 0;
    box-shadow: inset 0 0 0 1px #9f9f9f;
    box-shadow: inset 0 0 0 1.5px #9f9f9f;
    appearance: none;
    padding: 0;
    margin: 0;
    transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
    pointer-events: none;
    outline: none;
}

.l-radio>input:checked {
    box-shadow: inset 0 0 0 6px var(--blue);
}

.l-radio>span {
    vertical-align: middle;
    display: inline-block;
    line-height: 20px;
    padding: 0 8px;
}

.l-radio>img{
    margin-left: 5px;
}