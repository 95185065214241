.f18-600 {
    font-size: 18px;
    font-weight: 600;
}
.f20-500 {
    font-size: 20px;
    font-weight: 500;
}

.f32-600 {
    font-size: 32px;
    font-weight: 600;
}
