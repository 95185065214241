.renting_group_container {
    display: flex;
    flex-wrap: wrap;
}
.renting_select {
    border: 1px solid #cbd5e1;
    color: #cbd5e1;
    margin: 10px 10px 0 0;
    padding: 10px 16px;
    font-size: 16px;
    text-transform: capitalize;
}

.renting_select_selected {
    border: 1px solid #3460dc;
    color: #3460dc;
}
