.container {
    display: flex;
    gap: 0 16px;
}

.link {
    padding: 6px 12px;


    display: flex;
    align-items: center;
    gap: 0 10px;
}

.signup{
    border-bottom: 1px solid var(--primary-button-color);
    color: var(--primary-button-color);
}

.forgot_password{
    color: var(--dark-blue);
}

.row{
    display: flex;
    justify-content: space-between;
}
