.label {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: var(--blue);
    font-size: 12px;
    font-weight: 500;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto auto;
    gap: 10px;
    margin: 8px 0;
    padding: 6px;
}

.label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark_container {
    border: 2px solid var(--blue);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkmark_container>span {
    background-color: var(--blue);
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 50%;
    transform: scale(0);
    transition: all 180ms ease;
}

.label>input:checked+.block .checkmark_container>span {
    transform: scale(1);
}

.block {
    position: relative;
    width: 22px;
    height: 22px;
}