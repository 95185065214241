.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
align-items: center;
    flex:1;
    color: var(--dark-blue);
    max-width: 840px;
    width: 100%;
    margin: 0 auto;
    gap:  20px 0;
}

.row{
    display: flex;
    justify-content: space-between;
    width: 100%
}

.form{
    max-width: 406px;
     width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}

.row > .btn{

    color: rgba(212, 212, 212, 1);
}
