.container {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    user-select: none;
    background: none;
    border: none;
    gap:  16px;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    width: auto;
    white-space: nowrap;
    box-sizing: border-box;
    color: var(--primary-text-color);
    font-weight: 500;
}

.container[disabled] {
    background: rgb(233, 233, 233);
    color: white;
}

.container_primary {
    background-color: var(--primary-button-color);
    color: white;
    border: 1px solid var(--primary-button-color);
}

.container_outlined {
    background-color: white;
    border: 1px solid var(--primary-button-color);
    color: var(--primary-button-color);
}

.container_secondary {
    background-color: var(--secondary-button-color);
    color: var(--primary-button-color);
}

.container_link {
    text-decoration: underline;
    color: var(--primary-button-color);
}

.container_primary,
.container_outlined,
.container_secondary {
    justify-content: center;
    padding: 10px 32px;
    border-radius: 4px;
    transition: color 160ms linear;
}

.container_primary:hover,
.container_outlined:hover,
.container_secondary:hover {
    opacity: .5;
}
