.body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 22px 30px;
}

.title {
    margin: 40px 0 30px 0;
    color: rgba(41, 56, 68, 1);
}

.submit_btn {
    padding: 10px 80px;
    margin-top: 40px;
}

.upload_container {
    border-radius: 6px;
    width: auto !important;
    flex: 1
}

.upload_container span {
    color: var(--primary-button-color);
    border-bottom: 1px solid var(--primary-button-color);
}

.upload_container > div {
    text-align: center;
}

.row {
    display: flex;
    gap: 0 26px;
}

.privacy_policy_container {
    margin: 40px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 16px 0;
    border: 1px solid rgba(41, 56, 68, 1);
    padding: 20px;
}

.checkbox {
    font-size: 20px;
    font-weight: 500;
}

.text {
    color: rgba(41, 56, 68, 1);
    margin: 16px 0;
}

.block {
    border: 1px solid var(--dark-blue);
    padding: 16px 22px;
    background-color: rgba(248, 248, 248, 1);
    display: flex;
    flex-direction: column;
    gap: 16px 0;
}

.block > .list {
    border: 1px solid rgba(212, 212, 212, 1);
    border-radius: 4px;
    padding: 10px;
    transition: all 120ms linear;
    cursor: pointer;
}

.block .selected_plan {
    background-color: rgba(212, 212, 212, 1);
    color: rgba(0, 0, 0, 0.6);
}

.green_block {
    border: 1px solid rgba(67, 202, 178, 1);
}

.brown_block {
    border: 1px solid rgba(159, 143, 91, 1)
}

.green_block > div {
    line-height: 27px;
    color: rgba(57, 49, 34, 1);
}

.mark {
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    padding: 12px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    padding: 36px 100px;
    position: relative;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
}

.green_block > .mark {
    background-color: rgba(67, 202, 178, 1);
}

.brown_block > .mark {
    background-color: rgba(159, 143, 91, 1);
}

.brown_block li {
    font-size: 18px;
    font-weight: 400;
    color: rgba(159, 143, 91, 1);
    margin: 30px;
}

.brown_header {
    font-size: 24px;
    font-weight: 500;
    color: rgba(159, 143, 91, 1);
}
