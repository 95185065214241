.container {
    --form-control-color: #d2d2d2;
    --form-control-disabled: #d2d2d2;
    color: #646464;
    user-select: none;
    font-size: 16px !important;
    display: flex;
}

.form-control {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.1;
    width: inline !important;
    display: grid;
    grid-template-columns: 1em auto;
    grid-gap: 0 20px;
    align-items: center;
    cursor: pointer;
}

.form-control+.form-control {
    margin-top: 1em;
}

.form-control--disabled {
    color: white;
    cursor: not-allowed;
}

.form-control input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: #646464;
    width: 1.2em;
    height: 1.2em;
    border-radius: 3px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;
}

.form-control input[type="checkbox"]::before {
    content: "";
    width: 0.7em;
    height: 0.7em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em white;
    background-color: CanvasText;
    cursor: pointer;
}

input[type="checkbox"]:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

input[type="checkbox"]:checked {
    background: rgba(159, 143, 91, 1) ;
}

input[type="checkbox"]:not(:checked) {
    border: 2px solid #a7a7a7;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input[type="checkbox"]:disabled {
    --form-control-color: #d2d2d2;
    color: #d2d2d2;
    cursor: not-allowed;
}

.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    background-color: gray;
}
