.container {
    flex: 1;
    position: relative;
    height: 100%;
}


/* .scroll */
.block {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow-y: auto;
    padding: 0 6px 6px 0;
}

.block::-webkit-scrollbar-thumb {
    background: #919395;
    border-radius: 10px;
}

.block::-webkit-scrollbar,
.scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
