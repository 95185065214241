.container {
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    gap: 0 6px;
    font-size: 16px;
    width: 100%;
    color: #646464;
    text-align: right;
}

.block {
    background: #dedede;
}

.rating {
    height: 4px;
    background: var(--bgc);
}

.block,
.rating {
    border-radius: 20px;
}
