.container {
    overflow: hidden;
    height: 100%;
    min-height: 100%;
    flex: 1;
}

.block {
    display: flex;
    justify-content: flex-start;
    transition: all 280ms linear;
    height: 100%;
}

.tab {
    min-width: 100%;
    width: 100%;
    min-height: 100%;
    height: 100%;
}